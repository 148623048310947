import React from "react";
import { Link } from "react-router-dom";
import{ Row, Col, Table } from "reactstrap";

import SwitchInlineImg from 'assets/img/navbar/SWITCH/Inline/Series-7000-Inline.png';
import SwitchInsertionImg from 'assets/img/navbar/SWITCH/Insertion/Series-7000-Insertion.png';

import '../../MobileSideNavbar.css';
import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function Switch({mobileNavbarState}){
  const product_data = [
    {
      title: 'SWITCHGEN',
      link: 'gen',
      description: 'Flow Switch with an Explosion-Proof Rated Enclosure',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1/4-4"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: SwitchInlineImg,
      default_image: SwitchInlineImg,
      left_image: SwitchInlineImg,
      right_image: SwitchInsertionImg
    }
  ]

  const link_dir_inline = '/products/switch/inline/';
  const link_dir_insertion = '/products/switch/insertion/';

  return(
    <>
      <div id="switch" class="sidenav" style={{'padding-top': '50px'}}>
      <Table className="table-shopping" responsive>
        <tbody>
          { product_data.map((value) => {
            return(
              <tr>
                <td>
                  <div className="contentM text-center" style={{'width': '270px'}}>
                    <img style={{'width': '270px', 'height': 'auto'}} alt="..." src={value.image} ></img>
                    <p className="nav-product-title">{ value.title }</p>
                    <p className="nav-product-description">{ value.description }</p>
                    <Row>
                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_inline + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Inline
                      </Col>

                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_insertion + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Insertion
                      </Col>
                    </Row>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </div>
    </>
  )
}

export default Switch;
